import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

interface ImageWithSkeletonProps {
  src: string
  height: number
  width: number
}

const ImageWithSkeleton = ({ height, width, src }: ImageWithSkeletonProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <>
      {!isLoaded && <Skeleton height={`${height}px`} width={`${width}px`} variant="rectangular" />}
      <img src={src} height={height} width={width} onLoad={handleImageLoaded} />
    </>
  );
};

export default ImageWithSkeleton;
